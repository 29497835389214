(function () {
'use strict';

const menu = document.querySelector(`.nav`);
const btnMenu = document.querySelector(`.header__btn-menu`);
const subMenu = document.querySelectorAll(`.nav__submenu`);
const btnSubMenu = document.querySelectorAll(`.nav__link--submenu`);

if (document.documentElement.clientWidth < 768) {
  if (btnMenu) {
    btnMenu.addEventListener(`click`, () => {
      btnMenu.classList.toggle(`header__btn-menu--close`);
      menu.classList.toggle(`nav--open`);
    });
  }
}

if (document.documentElement.clientWidth < 1170) {
  if (btnSubMenu) {
    [...btnSubMenu].forEach((item, index) => {
      item.addEventListener(`click`, (event) => {
        event.preventDefault();
        item.classList.toggle(`nav__link--submenu-open`);
        subMenu[index].classList.toggle(`nav__submenu--open`);
      });
    });
  }
}

const overlay = document.querySelector(`.overlay`);
const modal = document.querySelector(`.modal`);
const iframe = document.querySelector(`.modal iframe`);
const btnClose = document.querySelector(`.modal__btn-close`);
const showBtn = document.querySelector(`.page__about-video`);

if (showBtn) {
  showBtn.addEventListener(`click`, (event) => {
    event.preventDefault();

    overlay.classList.add(`overlay--show`);
    modal.classList.add(`modal--show`);

    const href = showBtn.dataset.video;

    iframe.setAttribute(`src`, href);
  });
}

if (btnClose) {
  btnClose.addEventListener(`click`, () => {
    iframe.setAttribute(`src`, ``);
    overlay.classList.remove(`overlay--show`);
    modal.classList.remove(`modal--show`);
  });
}

window.addEventListener(`keydown`, (event) => {
  if (event.keyCode === 27) {
    if (modal.classList.contains(`modal--show`)) {
      overlay.classList.remove(`overlay--show`);
      modal.classList.remove(`modal--show`);
    }
  }
});

$(`.clients__list`).slick({
  appendArrows: $(`.clients__btn-block`),
  nextArrow: `<button class="clients__btn clients__btn--next" type="button" title="Назад"></button>`,
  prevArrow: `<button class="clients__btn clients__btn--prev" type="button" title="Назад"></button>`,
  slidesToShow: 2,
  slidesToScroll: 2,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 1170,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 2,
        draggable: false
      }
    }
  ]
});

const btnOpenModal = document.querySelector(`.nav__btn`);
const modal$1 = document.querySelector(`.popup`);
const btnCloseModal = modal$1.querySelector(`.popup__btn-close`);

if (btnOpenModal) {
  btnOpenModal.addEventListener(`click`, (event) => {
    event.preventDefault();

    modal$1.classList.add(`popup--open`);
  });
}

if (btnCloseModal) {
  btnCloseModal.addEventListener(`click`, () => {
    modal$1.classList.remove(`popup--open`);
  });
}

window.addEventListener(`keydown`, (event) => {
  if (event.keyCode === 27) {
    if (modal$1.classList.contains(`modal--open`)) {
      modal$1.classList.remove(`modal--open`);
    }
  }
});

}());
